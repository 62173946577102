import { Datagrid, ImageField, List } from "react-admin";

export const BrandsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <ImageField
        source="image"
        label="Зображення бренду"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
    </Datagrid>
  </List>
);
