import { apolloClient } from "@app/core/apollo-client";
import {
  AdminGetUserQuery,
  AdminLoginQuery,
  AdminLoginQueryVariables,
  Role_Types_Enum,
} from "@app/core/types";
import AdminLoginQueryGql from "@app/core/graphql/admin-login.gql";
import AdminGetUserQueryGql from "@app/core/graphql/admin-get-user.gql";
import { JWT_USER_TOKEN } from "@app/core/constants";
import OneSignalReact from "react-onesignal";

export const authProvider = {
  login: async (variables: AdminLoginQueryVariables) => {
    const { data } = await apolloClient.query<
      AdminLoginQuery,
      AdminLoginQueryVariables
    >({
      query: AdminLoginQueryGql,
      variables,
      fetchPolicy: "no-cache",
    });

    if (!data.adminLogin?.accessToken) {
      return Promise.reject();
    }

    localStorage.setItem(JWT_USER_TOKEN, data.adminLogin.accessToken);
  },
  logout: () => {
    OneSignalReact.removeExternalUserId();
    localStorage.removeItem(JWT_USER_TOKEN);
    return Promise.resolve();
  },
  checkAuth: () => {
    if (!localStorage.getItem(JWT_USER_TOKEN)) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkError: (error: any) => {
    return Promise.resolve();
  },
  getIdentity: async () => {
    const { data } = await apolloClient.query<AdminGetUserQuery>({
      query: AdminGetUserQueryGql,
      fetchPolicy: "no-cache",
    });

    OneSignalReact.setExternalUserId(data.adminGetUser!.id);

    return Promise.resolve({
      id: data.adminGetUser!.id,
      fullName: data.adminGetUser!.username,
      role: data.adminGetUser!.role,
    });
  },
  getPermissions: async () => {
    const { data } = await apolloClient.query<AdminGetUserQuery>({
      query: AdminGetUserQueryGql,
    });

    const role = data.adminGetUser!.role;

    if (!role) {
      Promise.reject();
    }

    if (role === Role_Types_Enum.Admin) {
      Promise.resolve([
        { action: ["read", "create", "edit", "export"], resource: "services" },
        {
          action: ["read", "create", "edit", "export"],
          resource: "services_category",
        },
        { action: ["read", "create", "edit", "export"], resource: "users" },
      ]);
    }

    if (role === Role_Types_Enum.Manager) {
      Promise.resolve([
        { action: ["read", "create", "edit", "export"], resource: "services" },
        {
          action: ["read", "create", "edit", "export"],
          resource: "services_category",
        },
      ]);
    }

    if (role === Role_Types_Enum.User) {
      Promise.resolve([
        { action: ["read",], resource: "services" },
        {
          action: ["read"],
          resource: "services_category",
        },
      ]);
    }

    return role ? Promise.resolve(role) : Promise.reject();
  },
};
