import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  uuid: any;
};

export type AdminGetUserOutput = {
  __typename?: 'AdminGetUserOutput';
  id: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

export type AdminLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AdminLoginOutput = {
  __typename?: 'AdminLoginOutput';
  accessToken: Scalars['String'];
};

export type AdminRegisterInput = {
  password: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

export type AdminRegisterOutput = {
  __typename?: 'AdminRegisterOutput';
  accessToken: Scalars['String'];
};

export type CloudinarySignatureOutput = {
  __typename?: 'CloudinarySignatureOutput';
  apiKey: Scalars['String'];
  cloudName: Scalars['String'];
  publicId: Scalars['String'];
  signature: Scalars['String'];
  timestamp: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "brands" */
export type Brands = {
  __typename?: 'brands';
  id: Scalars['uuid'];
  image: Scalars['String'];
};

/** aggregated selection of "brands" */
export type Brands_Aggregate = {
  __typename?: 'brands_aggregate';
  aggregate?: Maybe<Brands_Aggregate_Fields>;
  nodes: Array<Brands>;
};

/** aggregate fields of "brands" */
export type Brands_Aggregate_Fields = {
  __typename?: 'brands_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brands_Max_Fields>;
  min?: Maybe<Brands_Min_Fields>;
};


/** aggregate fields of "brands" */
export type Brands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Brands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
export type Brands_Bool_Exp = {
  _and?: InputMaybe<Array<Brands_Bool_Exp>>;
  _not?: InputMaybe<Brands_Bool_Exp>;
  _or?: InputMaybe<Array<Brands_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "brands" */
export enum Brands_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrandsPkey = 'brands_pkey'
}

/** input type for inserting data into table "brands" */
export type Brands_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Brands_Max_Fields = {
  __typename?: 'brands_max_fields';
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Brands_Min_Fields = {
  __typename?: 'brands_min_fields';
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "brands" */
export type Brands_Mutation_Response = {
  __typename?: 'brands_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brands>;
};

/** on_conflict condition type for table "brands" */
export type Brands_On_Conflict = {
  constraint: Brands_Constraint;
  update_columns?: Array<Brands_Update_Column>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

/** Ordering options when selecting data from "brands". */
export type Brands_Order_By = {
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** primary key columns input for table: brands */
export type Brands_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "brands" */
export enum Brands_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

/** input type for updating data in table "brands" */
export type Brands_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "brands" */
export type Brands_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brands_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brands_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** update columns of table "brands" */
export enum Brands_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

export type Brands_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Brands_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brands_Bool_Exp;
};

/** columns and relationships of "certificates" */
export type Certificates = {
  __typename?: 'certificates';
  id: Scalars['uuid'];
  image: Scalars['String'];
};

/** aggregated selection of "certificates" */
export type Certificates_Aggregate = {
  __typename?: 'certificates_aggregate';
  aggregate?: Maybe<Certificates_Aggregate_Fields>;
  nodes: Array<Certificates>;
};

/** aggregate fields of "certificates" */
export type Certificates_Aggregate_Fields = {
  __typename?: 'certificates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Certificates_Max_Fields>;
  min?: Maybe<Certificates_Min_Fields>;
};


/** aggregate fields of "certificates" */
export type Certificates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Certificates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "certificates". All fields are combined with a logical 'AND'. */
export type Certificates_Bool_Exp = {
  _and?: InputMaybe<Array<Certificates_Bool_Exp>>;
  _not?: InputMaybe<Certificates_Bool_Exp>;
  _or?: InputMaybe<Array<Certificates_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "certificates" */
export enum Certificates_Constraint {
  /** unique or primary key constraint on columns "id" */
  CertificatesPkey = 'certificates_pkey'
}

/** input type for inserting data into table "certificates" */
export type Certificates_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Certificates_Max_Fields = {
  __typename?: 'certificates_max_fields';
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Certificates_Min_Fields = {
  __typename?: 'certificates_min_fields';
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "certificates" */
export type Certificates_Mutation_Response = {
  __typename?: 'certificates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Certificates>;
};

/** on_conflict condition type for table "certificates" */
export type Certificates_On_Conflict = {
  constraint: Certificates_Constraint;
  update_columns?: Array<Certificates_Update_Column>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};

/** Ordering options when selecting data from "certificates". */
export type Certificates_Order_By = {
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** primary key columns input for table: certificates */
export type Certificates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "certificates" */
export enum Certificates_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

/** input type for updating data in table "certificates" */
export type Certificates_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "certificates" */
export type Certificates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Certificates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Certificates_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
};

/** update columns of table "certificates" */
export enum Certificates_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

export type Certificates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Certificates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Certificates_Bool_Exp;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: 'contacts';
  id: Scalars['uuid'];
  link: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey'
}

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

export type Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  adminRegister?: Maybe<AdminRegisterOutput>;
  /** delete data from the table: "brands" */
  delete_brands?: Maybe<Brands_Mutation_Response>;
  /** delete single row from the table: "brands" */
  delete_brands_by_pk?: Maybe<Brands>;
  /** delete data from the table: "certificates" */
  delete_certificates?: Maybe<Certificates_Mutation_Response>;
  /** delete single row from the table: "certificates" */
  delete_certificates_by_pk?: Maybe<Certificates>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "prices" */
  delete_prices?: Maybe<Prices_Mutation_Response>;
  /** delete single row from the table: "prices" */
  delete_prices_by_pk?: Maybe<Prices>;
  /** delete data from the table: "role_types" */
  delete_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** delete single row from the table: "role_types" */
  delete_role_types_by_pk?: Maybe<Role_Types>;
  /** delete data from the table: "services" */
  delete_services?: Maybe<Services_Mutation_Response>;
  /** delete single row from the table: "services" */
  delete_services_by_pk?: Maybe<Services>;
  /** delete data from the table: "services_category" */
  delete_services_category?: Maybe<Services_Category_Mutation_Response>;
  /** delete single row from the table: "services_category" */
  delete_services_category_by_pk?: Maybe<Services_Category>;
  /** delete data from the table: "specialists" */
  delete_specialists?: Maybe<Specialists_Mutation_Response>;
  /** delete single row from the table: "specialists" */
  delete_specialists_by_pk?: Maybe<Specialists>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "brands" */
  insert_brands?: Maybe<Brands_Mutation_Response>;
  /** insert a single row into the table: "brands" */
  insert_brands_one?: Maybe<Brands>;
  /** insert data into the table: "certificates" */
  insert_certificates?: Maybe<Certificates_Mutation_Response>;
  /** insert a single row into the table: "certificates" */
  insert_certificates_one?: Maybe<Certificates>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "prices" */
  insert_prices?: Maybe<Prices_Mutation_Response>;
  /** insert a single row into the table: "prices" */
  insert_prices_one?: Maybe<Prices>;
  /** insert data into the table: "role_types" */
  insert_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** insert a single row into the table: "role_types" */
  insert_role_types_one?: Maybe<Role_Types>;
  /** insert data into the table: "services" */
  insert_services?: Maybe<Services_Mutation_Response>;
  /** insert data into the table: "services_category" */
  insert_services_category?: Maybe<Services_Category_Mutation_Response>;
  /** insert a single row into the table: "services_category" */
  insert_services_category_one?: Maybe<Services_Category>;
  /** insert a single row into the table: "services" */
  insert_services_one?: Maybe<Services>;
  /** insert data into the table: "specialists" */
  insert_specialists?: Maybe<Specialists_Mutation_Response>;
  /** insert a single row into the table: "specialists" */
  insert_specialists_one?: Maybe<Specialists>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "brands" */
  update_brands?: Maybe<Brands_Mutation_Response>;
  /** update single row of the table: "brands" */
  update_brands_by_pk?: Maybe<Brands>;
  /** update multiples rows of table: "brands" */
  update_brands_many?: Maybe<Array<Maybe<Brands_Mutation_Response>>>;
  /** update data of the table: "certificates" */
  update_certificates?: Maybe<Certificates_Mutation_Response>;
  /** update single row of the table: "certificates" */
  update_certificates_by_pk?: Maybe<Certificates>;
  /** update multiples rows of table: "certificates" */
  update_certificates_many?: Maybe<Array<Maybe<Certificates_Mutation_Response>>>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "prices" */
  update_prices?: Maybe<Prices_Mutation_Response>;
  /** update single row of the table: "prices" */
  update_prices_by_pk?: Maybe<Prices>;
  /** update multiples rows of table: "prices" */
  update_prices_many?: Maybe<Array<Maybe<Prices_Mutation_Response>>>;
  /** update data of the table: "role_types" */
  update_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** update single row of the table: "role_types" */
  update_role_types_by_pk?: Maybe<Role_Types>;
  /** update multiples rows of table: "role_types" */
  update_role_types_many?: Maybe<Array<Maybe<Role_Types_Mutation_Response>>>;
  /** update data of the table: "services" */
  update_services?: Maybe<Services_Mutation_Response>;
  /** update single row of the table: "services" */
  update_services_by_pk?: Maybe<Services>;
  /** update data of the table: "services_category" */
  update_services_category?: Maybe<Services_Category_Mutation_Response>;
  /** update single row of the table: "services_category" */
  update_services_category_by_pk?: Maybe<Services_Category>;
  /** update multiples rows of table: "services_category" */
  update_services_category_many?: Maybe<Array<Maybe<Services_Category_Mutation_Response>>>;
  /** update multiples rows of table: "services" */
  update_services_many?: Maybe<Array<Maybe<Services_Mutation_Response>>>;
  /** update data of the table: "specialists" */
  update_specialists?: Maybe<Specialists_Mutation_Response>;
  /** update single row of the table: "specialists" */
  update_specialists_by_pk?: Maybe<Specialists>;
  /** update multiples rows of table: "specialists" */
  update_specialists_many?: Maybe<Array<Maybe<Specialists_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAdminRegisterArgs = {
  user: AdminRegisterInput;
};


/** mutation root */
export type Mutation_RootDelete_BrandsArgs = {
  where: Brands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brands_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CertificatesArgs = {
  where: Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PricesArgs = {
  where: Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Role_TypesArgs = {
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Types_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ServicesArgs = {
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Services_CategoryArgs = {
  where: Services_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SpecialistsArgs = {
  where: Specialists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Specialists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_BrandsArgs = {
  objects: Array<Brands_Insert_Input>;
  on_conflict?: InputMaybe<Brands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brands_OneArgs = {
  object: Brands_Insert_Input;
  on_conflict?: InputMaybe<Brands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CertificatesArgs = {
  objects: Array<Certificates_Insert_Input>;
  on_conflict?: InputMaybe<Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificates_OneArgs = {
  object: Certificates_Insert_Input;
  on_conflict?: InputMaybe<Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PricesArgs = {
  objects: Array<Prices_Insert_Input>;
  on_conflict?: InputMaybe<Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prices_OneArgs = {
  object: Prices_Insert_Input;
  on_conflict?: InputMaybe<Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_TypesArgs = {
  objects: Array<Role_Types_Insert_Input>;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Types_OneArgs = {
  object: Role_Types_Insert_Input;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServicesArgs = {
  objects: Array<Services_Insert_Input>;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_CategoryArgs = {
  objects: Array<Services_Category_Insert_Input>;
  on_conflict?: InputMaybe<Services_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_Category_OneArgs = {
  object: Services_Category_Insert_Input;
  on_conflict?: InputMaybe<Services_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_OneArgs = {
  object: Services_Insert_Input;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SpecialistsArgs = {
  objects: Array<Specialists_Insert_Input>;
  on_conflict?: InputMaybe<Specialists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Specialists_OneArgs = {
  object: Specialists_Insert_Input;
  on_conflict?: InputMaybe<Specialists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_BrandsArgs = {
  _set?: InputMaybe<Brands_Set_Input>;
  where: Brands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brands_By_PkArgs = {
  _set?: InputMaybe<Brands_Set_Input>;
  pk_columns: Brands_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brands_ManyArgs = {
  updates: Array<Brands_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CertificatesArgs = {
  _set?: InputMaybe<Certificates_Set_Input>;
  where: Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificates_By_PkArgs = {
  _set?: InputMaybe<Certificates_Set_Input>;
  pk_columns: Certificates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificates_ManyArgs = {
  updates: Array<Certificates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PricesArgs = {
  _inc?: InputMaybe<Prices_Inc_Input>;
  _set?: InputMaybe<Prices_Set_Input>;
  where: Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prices_By_PkArgs = {
  _inc?: InputMaybe<Prices_Inc_Input>;
  _set?: InputMaybe<Prices_Set_Input>;
  pk_columns: Prices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prices_ManyArgs = {
  updates: Array<Prices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_TypesArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_By_PkArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  pk_columns: Role_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_ManyArgs = {
  updates: Array<Role_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServicesArgs = {
  _set?: InputMaybe<Services_Set_Input>;
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_By_PkArgs = {
  _set?: InputMaybe<Services_Set_Input>;
  pk_columns: Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Services_CategoryArgs = {
  _set?: InputMaybe<Services_Category_Set_Input>;
  where: Services_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Category_By_PkArgs = {
  _set?: InputMaybe<Services_Category_Set_Input>;
  pk_columns: Services_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Category_ManyArgs = {
  updates: Array<Services_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Services_ManyArgs = {
  updates: Array<Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SpecialistsArgs = {
  _inc?: InputMaybe<Specialists_Inc_Input>;
  _set?: InputMaybe<Specialists_Set_Input>;
  where: Specialists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Specialists_By_PkArgs = {
  _inc?: InputMaybe<Specialists_Inc_Input>;
  _set?: InputMaybe<Specialists_Set_Input>;
  pk_columns: Specialists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Specialists_ManyArgs = {
  updates: Array<Specialists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "prices" */
export type Prices = {
  __typename?: 'prices';
  category: Scalars['String'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  price: Scalars['Int'];
  sub_category?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** aggregated selection of "prices" */
export type Prices_Aggregate = {
  __typename?: 'prices_aggregate';
  aggregate?: Maybe<Prices_Aggregate_Fields>;
  nodes: Array<Prices>;
};

/** aggregate fields of "prices" */
export type Prices_Aggregate_Fields = {
  __typename?: 'prices_aggregate_fields';
  avg?: Maybe<Prices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Prices_Max_Fields>;
  min?: Maybe<Prices_Min_Fields>;
  stddev?: Maybe<Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Prices_Sum_Fields>;
  var_pop?: Maybe<Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Prices_Var_Samp_Fields>;
  variance?: Maybe<Prices_Variance_Fields>;
};


/** aggregate fields of "prices" */
export type Prices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Prices_Avg_Fields = {
  __typename?: 'prices_avg_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "prices". All fields are combined with a logical 'AND'. */
export type Prices_Bool_Exp = {
  _and?: InputMaybe<Array<Prices_Bool_Exp>>;
  _not?: InputMaybe<Prices_Bool_Exp>;
  _or?: InputMaybe<Array<Prices_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  sub_category?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "prices" */
export enum Prices_Constraint {
  /** unique or primary key constraint on columns "id" */
  PricesPkey = 'prices_pkey'
}

/** input type for incrementing numeric columns in table "prices" */
export type Prices_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prices" */
export type Prices_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  sub_category?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Prices_Max_Fields = {
  __typename?: 'prices_max_fields';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Prices_Min_Fields = {
  __typename?: 'prices_min_fields';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "prices" */
export type Prices_Mutation_Response = {
  __typename?: 'prices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prices>;
};

/** on_conflict condition type for table "prices" */
export type Prices_On_Conflict = {
  constraint: Prices_Constraint;
  update_columns?: Array<Prices_Update_Column>;
  where?: InputMaybe<Prices_Bool_Exp>;
};

/** Ordering options when selecting data from "prices". */
export type Prices_Order_By = {
  category?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prices */
export type Prices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prices" */
export enum Prices_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Price = 'price',
  /** column name */
  SubCategory = 'sub_category',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "prices" */
export type Prices_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  sub_category?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Prices_Stddev_Fields = {
  __typename?: 'prices_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Prices_Stddev_Pop_Fields = {
  __typename?: 'prices_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Prices_Stddev_Samp_Fields = {
  __typename?: 'prices_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "prices" */
export type Prices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prices_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  sub_category?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Prices_Sum_Fields = {
  __typename?: 'prices_sum_fields';
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** update columns of table "prices" */
export enum Prices_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Price = 'price',
  /** column name */
  SubCategory = 'sub_category',
  /** column name */
  Title = 'title'
}

export type Prices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prices_Var_Pop_Fields = {
  __typename?: 'prices_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Prices_Var_Samp_Fields = {
  __typename?: 'prices_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Prices_Variance_Fields = {
  __typename?: 'prices_variance_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  adminGetUser?: Maybe<AdminGetUserOutput>;
  /** Login Admin */
  adminLogin?: Maybe<AdminLoginOutput>;
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brands_Aggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table: "certificates" */
  certificates: Array<Certificates>;
  /** fetch aggregated fields from the table: "certificates" */
  certificates_aggregate: Certificates_Aggregate;
  /** fetch data from the table: "certificates" using primary key columns */
  certificates_by_pk?: Maybe<Certificates>;
  cloudinarySignature?: Maybe<CloudinarySignatureOutput>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "prices" */
  prices: Array<Prices>;
  /** fetch aggregated fields from the table: "prices" */
  prices_aggregate: Prices_Aggregate;
  /** fetch data from the table: "prices" using primary key columns */
  prices_by_pk?: Maybe<Prices>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "services_category" */
  services_category: Array<Services_Category>;
  /** fetch aggregated fields from the table: "services_category" */
  services_category_aggregate: Services_Category_Aggregate;
  /** fetch data from the table: "services_category" using primary key columns */
  services_category_by_pk?: Maybe<Services_Category>;
  /** fetch data from the table: "specialists" */
  specialists: Array<Specialists>;
  /** fetch aggregated fields from the table: "specialists" */
  specialists_aggregate: Specialists_Aggregate;
  /** fetch data from the table: "specialists" using primary key columns */
  specialists_by_pk?: Maybe<Specialists>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAdminLoginArgs = {
  admin: AdminLoginInput;
};


export type Query_RootBrandsArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};


export type Query_RootBrands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};


export type Query_RootBrands_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCertificatesArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Query_RootCertificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Query_RootCertificates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPricesArgs = {
  distinct_on?: InputMaybe<Array<Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prices_Order_By>>;
  where?: InputMaybe<Prices_Bool_Exp>;
};


export type Query_RootPrices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prices_Order_By>>;
  where?: InputMaybe<Prices_Bool_Exp>;
};


export type Query_RootPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Query_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Query_RootServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServices_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Services_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Category_Order_By>>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};


export type Query_RootServices_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Category_Order_By>>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};


export type Query_RootServices_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSpecialistsArgs = {
  distinct_on?: InputMaybe<Array<Specialists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Specialists_Order_By>>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};


export type Query_RootSpecialists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Specialists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Specialists_Order_By>>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};


export type Query_RootSpecialists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "role_types" */
export type Role_Types = {
  __typename?: 'role_types';
  role: Scalars['String'];
};

/** aggregated selection of "role_types" */
export type Role_Types_Aggregate = {
  __typename?: 'role_types_aggregate';
  aggregate?: Maybe<Role_Types_Aggregate_Fields>;
  nodes: Array<Role_Types>;
};

/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_Fields = {
  __typename?: 'role_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Types_Max_Fields>;
  min?: Maybe<Role_Types_Min_Fields>;
};


/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_types". All fields are combined with a logical 'AND'. */
export type Role_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  _not?: InputMaybe<Role_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_types" */
export enum Role_Types_Constraint {
  /** unique or primary key constraint on columns "role" */
  RoleTypesPkey = 'role_types_pkey'
}

export enum Role_Types_Enum {
  Admin = 'admin',
  Manager = 'manager',
  User = 'user'
}

/** Boolean expression to compare columns of type "role_types_enum". All fields are combined with logical 'AND'. */
export type Role_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Types_Enum>;
  _in?: InputMaybe<Array<Role_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Role_Types_Enum>;
  _nin?: InputMaybe<Array<Role_Types_Enum>>;
};

/** input type for inserting data into table "role_types" */
export type Role_Types_Insert_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Types_Max_Fields = {
  __typename?: 'role_types_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Types_Min_Fields = {
  __typename?: 'role_types_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_types" */
export type Role_Types_Mutation_Response = {
  __typename?: 'role_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Types>;
};

/** on_conflict condition type for table "role_types" */
export type Role_Types_On_Conflict = {
  constraint: Role_Types_Constraint;
  update_columns?: Array<Role_Types_Update_Column>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "role_types". */
export type Role_Types_Order_By = {
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_types */
export type Role_Types_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "role_types" */
export enum Role_Types_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "role_types" */
export type Role_Types_Set_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role_types" */
export type Role_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Types_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role_types" */
export enum Role_Types_Update_Column {
  /** column name */
  Role = 'role'
}

export type Role_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Types_Bool_Exp;
};

/** columns and relationships of "services" */
export type Services = {
  __typename?: 'services';
  category_id?: Maybe<Scalars['uuid']>;
  details_content?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image_details?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "services" */
export type Services_Aggregate = {
  __typename?: 'services_aggregate';
  aggregate?: Maybe<Services_Aggregate_Fields>;
  nodes: Array<Services>;
};

/** aggregate fields of "services" */
export type Services_Aggregate_Fields = {
  __typename?: 'services_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Services_Max_Fields>;
  min?: Maybe<Services_Min_Fields>;
};


/** aggregate fields of "services" */
export type Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Services_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Bool_Exp>>;
  _not?: InputMaybe<Services_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Bool_Exp>>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  details_content?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_details?: InputMaybe<String_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  meta_title?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "services_category" */
export type Services_Category = {
  __typename?: 'services_category';
  details_content?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image_details?: Maybe<Scalars['String']>;
  image_main_page?: Maybe<Scalars['String']>;
  image_menu?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "services_category" */
export type Services_Category_Aggregate = {
  __typename?: 'services_category_aggregate';
  aggregate?: Maybe<Services_Category_Aggregate_Fields>;
  nodes: Array<Services_Category>;
};

/** aggregate fields of "services_category" */
export type Services_Category_Aggregate_Fields = {
  __typename?: 'services_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Services_Category_Max_Fields>;
  min?: Maybe<Services_Category_Min_Fields>;
};


/** aggregate fields of "services_category" */
export type Services_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "services_category". All fields are combined with a logical 'AND'. */
export type Services_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Category_Bool_Exp>>;
  _not?: InputMaybe<Services_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Category_Bool_Exp>>;
  details_content?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_details?: InputMaybe<String_Comparison_Exp>;
  image_main_page?: InputMaybe<String_Comparison_Exp>;
  image_menu?: InputMaybe<String_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  meta_title?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "services_category" */
export enum Services_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesCategoryPkey = 'services_category_pkey',
  /** unique or primary key constraint on columns "url" */
  ServicesCategoryUrlKey = 'services_category_url_key'
}

/** input type for inserting data into table "services_category" */
export type Services_Category_Insert_Input = {
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  image_main_page?: InputMaybe<Scalars['String']>;
  image_menu?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Services_Category_Max_Fields = {
  __typename?: 'services_category_max_fields';
  details_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_details?: Maybe<Scalars['String']>;
  image_main_page?: Maybe<Scalars['String']>;
  image_menu?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Services_Category_Min_Fields = {
  __typename?: 'services_category_min_fields';
  details_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_details?: Maybe<Scalars['String']>;
  image_main_page?: Maybe<Scalars['String']>;
  image_menu?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "services_category" */
export type Services_Category_Mutation_Response = {
  __typename?: 'services_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services_Category>;
};

/** on_conflict condition type for table "services_category" */
export type Services_Category_On_Conflict = {
  constraint: Services_Category_Constraint;
  update_columns?: Array<Services_Category_Update_Column>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "services_category". */
export type Services_Category_Order_By = {
  details_content?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_details?: InputMaybe<Order_By>;
  image_main_page?: InputMaybe<Order_By>;
  image_menu?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  meta_title?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services_category */
export type Services_Category_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "services_category" */
export enum Services_Category_Select_Column {
  /** column name */
  DetailsContent = 'details_content',
  /** column name */
  Id = 'id',
  /** column name */
  ImageDetails = 'image_details',
  /** column name */
  ImageMainPage = 'image_main_page',
  /** column name */
  ImageMenu = 'image_menu',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "services_category" */
export type Services_Category_Set_Input = {
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  image_main_page?: InputMaybe<Scalars['String']>;
  image_menu?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "services_category" */
export type Services_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Category_Stream_Cursor_Value_Input = {
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  image_main_page?: InputMaybe<Scalars['String']>;
  image_menu?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "services_category" */
export enum Services_Category_Update_Column {
  /** column name */
  DetailsContent = 'details_content',
  /** column name */
  Id = 'id',
  /** column name */
  ImageDetails = 'image_details',
  /** column name */
  ImageMainPage = 'image_main_page',
  /** column name */
  ImageMenu = 'image_menu',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

export type Services_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Category_Bool_Exp;
};

/** unique or primary key constraints on table "services" */
export enum Services_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesPkey = 'services_pkey',
  /** unique or primary key constraint on columns "url" */
  ServicesUrlKey = 'services_url_key'
}

/** input type for inserting data into table "services" */
export type Services_Insert_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Services_Max_Fields = {
  __typename?: 'services_max_fields';
  category_id?: Maybe<Scalars['uuid']>;
  details_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_details?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Services_Min_Fields = {
  __typename?: 'services_min_fields';
  category_id?: Maybe<Scalars['uuid']>;
  details_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_details?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "services" */
export type Services_Mutation_Response = {
  __typename?: 'services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services>;
};

/** on_conflict condition type for table "services" */
export type Services_On_Conflict = {
  constraint: Services_Constraint;
  update_columns?: Array<Services_Update_Column>;
  where?: InputMaybe<Services_Bool_Exp>;
};

/** Ordering options when selecting data from "services". */
export type Services_Order_By = {
  category_id?: InputMaybe<Order_By>;
  details_content?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_details?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  meta_title?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services */
export type Services_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "services" */
export enum Services_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DetailsContent = 'details_content',
  /** column name */
  Id = 'id',
  /** column name */
  ImageDetails = 'image_details',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "services" */
export type Services_Set_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "services" */
export type Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  details_content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_details?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "services" */
export enum Services_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DetailsContent = 'details_content',
  /** column name */
  Id = 'id',
  /** column name */
  ImageDetails = 'image_details',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

export type Services_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Bool_Exp;
};

/** columns and relationships of "specialists" */
export type Specialists = {
  __typename?: 'specialists';
  about: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "specialists" */
export type Specialists_Aggregate = {
  __typename?: 'specialists_aggregate';
  aggregate?: Maybe<Specialists_Aggregate_Fields>;
  nodes: Array<Specialists>;
};

/** aggregate fields of "specialists" */
export type Specialists_Aggregate_Fields = {
  __typename?: 'specialists_aggregate_fields';
  avg?: Maybe<Specialists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Specialists_Max_Fields>;
  min?: Maybe<Specialists_Min_Fields>;
  stddev?: Maybe<Specialists_Stddev_Fields>;
  stddev_pop?: Maybe<Specialists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Specialists_Stddev_Samp_Fields>;
  sum?: Maybe<Specialists_Sum_Fields>;
  var_pop?: Maybe<Specialists_Var_Pop_Fields>;
  var_samp?: Maybe<Specialists_Var_Samp_Fields>;
  variance?: Maybe<Specialists_Variance_Fields>;
};


/** aggregate fields of "specialists" */
export type Specialists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Specialists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Specialists_Avg_Fields = {
  __typename?: 'specialists_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "specialists". All fields are combined with a logical 'AND'. */
export type Specialists_Bool_Exp = {
  _and?: InputMaybe<Array<Specialists_Bool_Exp>>;
  _not?: InputMaybe<Specialists_Bool_Exp>;
  _or?: InputMaybe<Array<Specialists_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "specialists" */
export enum Specialists_Constraint {
  /** unique or primary key constraint on columns "id" */
  SpecialistsPkey = 'specialists_pkey'
}

/** input type for incrementing numeric columns in table "specialists" */
export type Specialists_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "specialists" */
export type Specialists_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Specialists_Max_Fields = {
  __typename?: 'specialists_max_fields';
  about?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Specialists_Min_Fields = {
  __typename?: 'specialists_min_fields';
  about?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "specialists" */
export type Specialists_Mutation_Response = {
  __typename?: 'specialists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Specialists>;
};

/** on_conflict condition type for table "specialists" */
export type Specialists_On_Conflict = {
  constraint: Specialists_Constraint;
  update_columns?: Array<Specialists_Update_Column>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};

/** Ordering options when selecting data from "specialists". */
export type Specialists_Order_By = {
  about?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: specialists */
export type Specialists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "specialists" */
export enum Specialists_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "specialists" */
export type Specialists_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Specialists_Stddev_Fields = {
  __typename?: 'specialists_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Specialists_Stddev_Pop_Fields = {
  __typename?: 'specialists_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Specialists_Stddev_Samp_Fields = {
  __typename?: 'specialists_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "specialists" */
export type Specialists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Specialists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Specialists_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Specialists_Sum_Fields = {
  __typename?: 'specialists_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "specialists" */
export enum Specialists_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order'
}

export type Specialists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Specialists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Specialists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Specialists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Specialists_Var_Pop_Fields = {
  __typename?: 'specialists_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Specialists_Var_Samp_Fields = {
  __typename?: 'specialists_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Specialists_Variance_Fields = {
  __typename?: 'specialists_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brands_Aggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table in a streaming manner: "brands" */
  brands_stream: Array<Brands>;
  /** fetch data from the table: "certificates" */
  certificates: Array<Certificates>;
  /** fetch aggregated fields from the table: "certificates" */
  certificates_aggregate: Certificates_Aggregate;
  /** fetch data from the table: "certificates" using primary key columns */
  certificates_by_pk?: Maybe<Certificates>;
  /** fetch data from the table in a streaming manner: "certificates" */
  certificates_stream: Array<Certificates>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** fetch data from the table: "prices" */
  prices: Array<Prices>;
  /** fetch aggregated fields from the table: "prices" */
  prices_aggregate: Prices_Aggregate;
  /** fetch data from the table: "prices" using primary key columns */
  prices_by_pk?: Maybe<Prices>;
  /** fetch data from the table in a streaming manner: "prices" */
  prices_stream: Array<Prices>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table in a streaming manner: "role_types" */
  role_types_stream: Array<Role_Types>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "services_category" */
  services_category: Array<Services_Category>;
  /** fetch aggregated fields from the table: "services_category" */
  services_category_aggregate: Services_Category_Aggregate;
  /** fetch data from the table: "services_category" using primary key columns */
  services_category_by_pk?: Maybe<Services_Category>;
  /** fetch data from the table in a streaming manner: "services_category" */
  services_category_stream: Array<Services_Category>;
  /** fetch data from the table in a streaming manner: "services" */
  services_stream: Array<Services>;
  /** fetch data from the table: "specialists" */
  specialists: Array<Specialists>;
  /** fetch aggregated fields from the table: "specialists" */
  specialists_aggregate: Specialists_Aggregate;
  /** fetch data from the table: "specialists" using primary key columns */
  specialists_by_pk?: Maybe<Specialists>;
  /** fetch data from the table in a streaming manner: "specialists" */
  specialists_stream: Array<Specialists>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootBrandsArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};


export type Subscription_RootBrands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};


export type Subscription_RootBrands_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrands_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Brands_Stream_Cursor_Input>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};


export type Subscription_RootCertificatesArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootCertificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootCertificates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCertificates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Certificates_Stream_Cursor_Input>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootPricesArgs = {
  distinct_on?: InputMaybe<Array<Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prices_Order_By>>;
  where?: InputMaybe<Prices_Bool_Exp>;
};


export type Subscription_RootPrices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prices_Order_By>>;
  where?: InputMaybe<Prices_Bool_Exp>;
};


export type Subscription_RootPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPrices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prices_Stream_Cursor_Input>>;
  where?: InputMaybe<Prices_Bool_Exp>;
};


export type Subscription_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootRole_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServices_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Services_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Category_Order_By>>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};


export type Subscription_RootServices_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Category_Order_By>>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};


export type Subscription_RootServices_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServices_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Category_Bool_Exp>;
};


export type Subscription_RootServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootSpecialistsArgs = {
  distinct_on?: InputMaybe<Array<Specialists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Specialists_Order_By>>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};


export type Subscription_RootSpecialists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Specialists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Specialists_Order_By>>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};


export type Subscription_RootSpecialists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSpecialists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Specialists_Stream_Cursor_Input>>;
  where?: InputMaybe<Specialists_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  id: Scalars['uuid'];
  password: Scalars['String'];
  role: Role_Types_Enum;
  username: Scalars['String'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Role_Types_Enum_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  Username = 'username'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AdminGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetUserQuery = { __typename?: 'query_root', adminGetUser?: { __typename?: 'AdminGetUserOutput', id: string, username: string, role: string } | null };

export type AdminLoginQueryVariables = Exact<{
  password: Scalars['String'];
  username: Scalars['String'];
}>;


export type AdminLoginQuery = { __typename?: 'query_root', adminLogin?: { __typename?: 'AdminLoginOutput', accessToken: string } | null };

export type CloudinarySignatureQueryVariables = Exact<{ [key: string]: never; }>;


export type CloudinarySignatureQuery = { __typename?: 'query_root', cloudinarySignature?: { __typename?: 'CloudinarySignatureOutput', apiKey: string, cloudName: string, publicId: string, signature: string, timestamp: number } | null };


export const AdminGetUserDocument = gql`
    query AdminGetUser {
  adminGetUser {
    id
    username
    role
  }
}
    `;

/**
 * __useAdminGetUserQuery__
 *
 * To run a query within a React component, call `useAdminGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetUserQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
      }
export function useAdminGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
        }
export type AdminGetUserQueryHookResult = ReturnType<typeof useAdminGetUserQuery>;
export type AdminGetUserLazyQueryHookResult = ReturnType<typeof useAdminGetUserLazyQuery>;
export type AdminGetUserQueryResult = Apollo.QueryResult<AdminGetUserQuery, AdminGetUserQueryVariables>;
export const AdminLoginDocument = gql`
    query AdminLogin($password: String!, $username: String!) {
  adminLogin(admin: {password: $password, username: $username}) {
    accessToken
  }
}
    `;

/**
 * __useAdminLoginQuery__
 *
 * To run a query within a React component, call `useAdminLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLoginQuery({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useAdminLoginQuery(baseOptions: Apollo.QueryHookOptions<AdminLoginQuery, AdminLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminLoginQuery, AdminLoginQueryVariables>(AdminLoginDocument, options);
      }
export function useAdminLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLoginQuery, AdminLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminLoginQuery, AdminLoginQueryVariables>(AdminLoginDocument, options);
        }
export type AdminLoginQueryHookResult = ReturnType<typeof useAdminLoginQuery>;
export type AdminLoginLazyQueryHookResult = ReturnType<typeof useAdminLoginLazyQuery>;
export type AdminLoginQueryResult = Apollo.QueryResult<AdminLoginQuery, AdminLoginQueryVariables>;
export const CloudinarySignatureDocument = gql`
    query CloudinarySignature {
  cloudinarySignature {
    apiKey
    cloudName
    publicId
    signature
    timestamp
  }
}
    `;

/**
 * __useCloudinarySignatureQuery__
 *
 * To run a query within a React component, call `useCloudinarySignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudinarySignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudinarySignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudinarySignatureQuery(baseOptions?: Apollo.QueryHookOptions<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>(CloudinarySignatureDocument, options);
      }
export function useCloudinarySignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>(CloudinarySignatureDocument, options);
        }
export type CloudinarySignatureQueryHookResult = ReturnType<typeof useCloudinarySignatureQuery>;
export type CloudinarySignatureLazyQueryHookResult = ReturnType<typeof useCloudinarySignatureLazyQuery>;
export type CloudinarySignatureQueryResult = Apollo.QueryResult<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>;